import gql from 'graphql-tag';

import apolloClientProvider from '../../apolloClientProvider';

export const GET_SNACKBAR = gql`
{
  snackbar @client {
        open
        message
        error
    }
}
`;

export const SNACKBAR_MUTATION = gql`
    mutation setSnackbar($input: Snackbar!) {
        setSnackbar(input: $input) @client
    }
`;

/**
 * Abre o Snackbar com a mensagem informada
 * @function OPEN_SNACKBAR
 * @param {string} message Mensagem a ser exibida
 * @param {bool} error Mostrar como erro
 */
export const OPEN_SNACKBAR = (message, error = false) => {
    apolloClientProvider.client
        .mutate({
            mutation: SNACKBAR_MUTATION, variables:
            {
                input:
                    { open: true, message, error, __typename: "Snackbar", }
            }
        });
}

export const CLOSE_SNACKBAR = () => {
    apolloClientProvider.client
        .mutate({ mutation: SNACKBAR_MUTATION, variables: { input: { open: false, __typename: "Snackbar", } } });
}