import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import UsuarioForm from './Form/UsuarioForm';
import apolloClientProvider from '../../apolloClientProvider';
import * as actions from '../../store/actions'
import { empresaGestoraId } from '../../shared/gestao';

const styles = {
    avatar: {
        width: 240,
        height: 240,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
    },
    container: {
    },
};

const initialValues = {
    nome: '',
    cargo: '',
    departamento: '',
    email: '',
    perfil: { value: undefined, label: undefined },
    foto: '',
    senha: '',
    confirmacaoSenha: '',
};

class PureUsuariosCreate extends Component {
    state = {
        errors: null
    }

    resetForm = null;

    componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            apolloClientProvider.client.query({ query: actions.GET_USUARIO, variables: { id: id }, fetchPolicy: "network-only" })
                .then(({ data: { usuario } }) => {

                    const perfil = usuario.clienteusuarios ? {
                        value: usuario.clienteusuarios.find(c => c.clienteid === empresaGestoraId).perfil.id,
                        label: usuario.clienteusuarios.find(c => c.clienteid === empresaGestoraId).perfil.nome
                    } : initialValues.perfil

                    const resetData = {
                        id: id,
                        nome: usuario.nome ? usuario.nome : initialValues.nome,
                        cargo: usuario.cargo ? usuario.cargo : initialValues.cargo,
                        departamento: usuario.departamento ? usuario.departamento : initialValues.departamento,
                        email: usuario.email ? usuario.email : initialValues.email,
                        perfil,
                        foto: usuario.foto ? usuario.foto : initialValues.foto,
                        senha: '',
                        confirmacaoSenha: '',
                    }
                    this.resetForm({values: resetData});
                });
        }
    }

    handleSubmit = (values, addHandlers) => {
        const id = this.props.match.params.id;

        const submitData = {
            usuario: {
                id,
                username: values.username,
                email: values.email,
                nome: values.nome,
                cargo: values.cargo,
                departamento: values.departamento,
                foto: values.foto,
                senha: values.senha
            },
            perfilid: values.perfil.value
        };

        addHandlers(
            apolloClientProvider.client.mutate(
                {
                    mutation: actions.SET_USUARIO,
                    variables: { clienteusuario: submitData },
                    refetchQueries: [{ query: actions.GET_USUARIOS }],
                })
                .then(({ data }) => {
                    actions.OPEN_SNACKBAR('Usuário cadastrado!');
                    this.props.history.push('/usuarios');
                })
        )
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <UsuarioForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting, handleCancel }) => (
                        <React.Fragment>
                            {this.resetForm = handleCancel}
                            {form}
                        </React.Fragment>
                    )}
                </UsuarioForm>
                {this.state.errors}
            </div >
        );
    };
};

export default withStyles(styles)(withRouter(PureUsuariosCreate));