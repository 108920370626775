import * as Yup from 'yup';
import { validateCpfCnpj, somenteNumeros } from './shared/utilities';

Yup.setLocale({
  mixed: {
    notType: 'O valor ${originalValue} deve ser do tipo ${type}', // eslint-disable-line
    default: 'Não é válido',
    required: 'Requerido',
  },
  string: {
    email: 'E-mail inválido',
    min: 'Valor muito curto (mínimo ${min} caracteres)', // eslint-disable-line
    max: 'Valor muito longo (máximo ${max} caracteres)', // eslint-disable-line
  },
  number: {
    min: 'Valor muito baixo (mínimo ${min})', // eslint-disable-line
    max: 'Valor muito alto (máximo ${max})', // eslint-disable-line
    integer: 'O valor deve ser um número inteiro',
    positive: 'O valor deve ser positivo'
  },
  array: {
    min: 'Quantidade de itens insuficiente (mínimo ${min})', // eslint-disable-line
    max: 'Quantidade de itens excedida (máximo ${max})', // eslint-disable-line
  },
});

function cpf(message = "CPF inválido") {
  return this.test({
      message,
      name: 'Cpf',
      exclusive: true,
      test: function (value) { return (value == null || value === "" || somenteNumeros(value) === "" || validateCpfCnpj(value)); }
  })
}

Yup.addMethod(Yup.string, 'cpf', cpf);

function cpfCnpj(message = "CPF/CNPJ inválido") {
  return this.test({
      message,
      name: 'CpfCnpj',
      exclusive: true,
      test: function (value) { return (value == null || value === "" || somenteNumeros(value) === "" || validateCpfCnpj(value)); }
  })
}

Yup.addMethod(Yup.string, 'CpfCnpj', cpfCnpj);

export default Yup;