export const tiposTelefone = [
    { value: 1, label: 'Residencial', enumString: 'RESIDENCIAL' },
    { value: 2, label: 'Fixo', enumString: 'FIXO' },
    { value: 3, label: 'Fax', enumString: 'FAX' },
    { value: 4, label: 'Móvel', enumString: 'MOVEL' },
];

export const getTiposTelefoneLabel = (tipo) => {
    return Object.values(tiposTelefone).find(s => s.enumString === tipo).label;
}

export const tiposPessoa = [
    { value: 1, label: 'Física', enumString: 'FISICA' },
    { value: 2, label: 'Jurídica', enumString: 'JURIDICA' },
    { value: 3, label: 'Órgão Público Federal', enumString: 'ORGAO_PUBLICO_FEDERAL' },
    { value: 4, label: 'Órgão Público Estadual', enumString: 'ORGAO_PUBLICO_ESTADUAL' },
    { value: 5, label: 'Outros', enumString: 'OUTROS' },
];

export const getTiposPessoaLabel = (tipo) => {
    return Object.values(tiposPessoa).find(s => s.enumString === tipo).label;
}

export const empresaGestoraId = 1;