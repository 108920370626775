import React from 'react';
import { getIn } from 'formik'
import { TextField, withStyles } from '@material-ui/core';
import { fetchFromObject } from '../../../../../../shared/utilities';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 320,
        display: 'flex',
    }
});

const logradouroField = (props) => {
    const { formik, classes } = props;

    const nomeCampo = "logradouro", label = 'Logradouro'

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)
    
    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                required
                id={nomeCampo}
                label={label}
                value={fetchFromObject(formik.values, nomeCampo)}
                error={Boolean(touched && error)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default withStyles(styles)(logradouroField);
