import React from 'react';
import { getIn } from 'formik'
import { Avatar, withStyles, FormHelperText } from '@material-ui/core';
import avatarPlaceholder from '../../../../content/images/avatarUserCreatePlaceholder.svg'
import alterar from '../../../../content/images/avatarUserCreateAlterar.svg';
import { fetchFromObject } from '../../../../shared/utilities';

const avatarSize = 180;

const styles = theme => ({
    avatar: {
        margin: theme.spacing(1),
        transition: '.5s ease',
        backfaceVisibility: 'hidden',
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 1,
        '&:hover': {
            opacity: 0.3
        }
    },
    alterar: {
        margin: theme.spacing(1),
        transition: '.5s ease',
        opacity: 0,
        position: 'absolute',
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        '&:hover': {
            opacity: 1
        }
    },
    mensagemErro: {
        margin: 8,
        textAlign: 'center',
        position: 'absolute',
        top: avatarSize + 8,
        width: '100%'
    },
    container: {
        position: 'relative',
        width: avatarSize,
        height: avatarSize,
    }
});

const handleChange = (event, formik, nomeCampo) => {
    const file = event.target.files[0];

    if (!file) return;

    if (file.size > 1048576) {
        formik.setFieldTouched(nomeCampo, true, false);
        formik.setFieldError(nomeCampo, "O tamanho máximo da imagem é 1MB");
        return;
    }


    const reader = new FileReader();

    reader.addEventListener("load", function () {
        formik.setFieldValue(nomeCampo, reader.result);
    }, false);
    if (file)
        reader.readAsDataURL(file)
}

const UsuarioFotoField = (props) => {
    const { formik, classes } = props;

    const nomeCampo = 'foto'
    const fieldValue = fetchFromObject(formik.values, nomeCampo)
    const avatar = fieldValue ? fieldValue : avatarPlaceholder;
    let inputRef = React.createRef();

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    return (
        <React.Fragment>
            <input
                ref={inputRef}
                style={{ display: 'none' }}
                type="file"
                id={nomeCampo}
                accept="image/*"
                onChange={(event) => handleChange(event, formik, nomeCampo)}
                disabled={formik.isSubmitting}
                onBlur={formik.handleBlur}>
            </input>
            <div className={classes.container}>
                <Avatar
                    className={classes.avatar}
                    src={avatar} />
                <Avatar
                    className={classes.alterar}
                    src={alterar}
                    onClick={() => inputRef.current.click()} />
                <FormHelperText
                    className={classes.mensagemErro}
                    error={Boolean(touched && error)}>
                    {error && touched && (
                        error
                    )}
                </FormHelperText>
            </div>


            {/* <TextField
                type="file"
                className={classes.textField}
                id="foto"
                label="Foto"
                value={formik.values.foto}
                error={Boolean(
                    formik.touched.foto && formik.errors.foto
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={formik.errors.foto &&
                    formik.touched.foto && (
                        formik.errors.foto
                    )}
                FormHelperTextProps={{error: true}}
            /> */}

        </React.Fragment>
    )
}

export default withStyles(styles)(UsuarioFotoField);



