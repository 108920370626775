import React, { Fragment } from 'react';
import SideDrawer from '../../components/UI/SideDrawer/SideDrawer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    Content: {
        marginLeft: 200,
        height: '100%',
    }
});

const Layout = (props) => {
    const classes = useStyles();
    return <Fragment>
        <SideDrawer />
        <main className={classes.Content}>
            {props.children}
        </main>
    </Fragment>
}

export default Layout;