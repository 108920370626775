import React from 'react';
import { getIn } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/pt-br';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { fetchFromObject } from '../../../../shared/utilities';

moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        display: 'flex',
        width: 155,
    }
}));

/**
 * Função para setar o valor do Data e Hora no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 * @param {string} nomeCampo - Nome do campo no Formik
 */
const handleChange = (event, formik, nomeCampo) => {
    //alteração do horário para o fim do dia em questão
    let newTime;
    if(event != null)
        newTime = moment(event.endOf('day').format(event._f));
    else
        newTime = event;
    formik.setFieldValue(nomeCampo, newTime);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const handleBlur = (formik, nomeCampo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(nomeCampo, true, true);
};


const DataHoraEmissaoField = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;

    const nomeCampo = 'validade', label = 'Validade';

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <KeyboardDatePicker
                name={nomeCampo}
                id={nomeCampo}
                value={fetchFromObject(formik.values, nomeCampo)}
                onChange={(event) => handleChange(event, formik, nomeCampo)}
                onBlur={() => handleBlur(formik, nomeCampo)}
                ampm={false}
                autoOk
                format="DD/MM/YYYY"
                // handle clearing outside => pass plain array if you are not controlling value outside
                mask={value =>
                    value
                        ? [/[0-3]/, /\d/, "/", /0|1/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
                        : []
                }
                cancelLabel='Cancelar'
                clearLabel='Limpar'
                invalidDateMessage='Data inválida'
                className={classes.textField}
                label={label}
                error={Boolean(touched && error)}
                disabled={formik.isSubmitting || disabled}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{ error: true }}
            />
        </MuiPickersUtilsProvider>
    )
}

export default DataHoraEmissaoField;
