export const setAuth = (_, variables, { cache }) => {
    const user = variables.input;
    if (user && user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
    }
    cache.writeData({ data: { auth: variables.input } });
    return {
        __typename: 'Auth',
        auth: variables.input
    };
}