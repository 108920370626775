import gql from 'graphql-tag';

export const SET_STARTING_PAGE = gql`
    mutation setStartingPage($input: String!) {
        setStartingPage(input: $input) @client
    }
`;
export const GET_STARTING_PAGE = gql`
{
  startingPage @client
}
`;