import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, withStyles, FormHelperText, DialogTitle, Dialog, Button, DialogContent, DialogActions, TextField, IconButton, } from '@material-ui/core';
import { FieldArray } from 'formik';
import CloseIcon from '@material-ui/icons/Close'

import * as cores from '../../../../shared/cores';
import { formatTelefone } from '../../../../shared/utilities';

function NumberFormatCustom(props) {
    const { inputRef, onChange, name, id, ...other } = props;

    return (
        <NumberFormat
            {...other}
            allowNegative={false}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                        name,
                        id,
                    },
                });
            }}
            format={formatTelefone}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const styles = (theme) => ({
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 100
    },
    telefonesDisplay: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 280
    },
    telefone: {
        display: 'flex',
    },
    botaoRemover: {
        alignSelf: 'center',
    },
    title: {
        color: cores.titulo,
    },
});

const novoTelefone = {
    id: undefined,
    tipo: 0,
    numero: '',
    ramal: ''
}

class EmpresaTelefonesField extends Component {
    state = {
        open: false
    }

    handleDialogOpen = () => {
        this.setState({ open: true, });
    };

    handleDialogClose = (event) => {
        this.setState({ open: false, });
    };

    /**
     * Função para setar o valor do Tipo de Pessoa no Formik
     * @method handleChange
     * @param {object} event - Evento
     * @param {object} formik - Props do formik obtidas por RenderProps
     */
    handleChange = (event, formik, campo) => {
        formik.setFieldValue(campo, event.target.value);
    }

    handleBlur = (formik, campo) => {
        // this is going to call setFieldTouched and manually update touched.topcis
        formik.setFieldTouched(campo, true, true);
    };

    adicionarTelefone = (arrayHelpers) => {
        arrayHelpers.push({ ...novoTelefone });
    }

    removerTelefone = (arrayHelpers, index) => {
        arrayHelpers.remove(index);
    }

    render() {
        const { formik, classes, getIn } = this.props;

        const numerosTelefone = formik.values.telefones.slice(0, 2)
            .reduce((total, num) => total.concat(num.numero), []);
        numerosTelefone.forEach((valor, index) => {
            if ((index % 2) === 0 && numerosTelefone.length !== 1)
                numerosTelefone.splice(index + 1, 0, ' / ');
        });

        const telefonesDisplay = numerosTelefone.reduce(
            (total, num) => num !== ' / ' ? total.concat(formatTelefone(num)) : total.concat(num)
            , '');

        return <React.Fragment>
            <TextField
                id="TelefonesPrincipais"
                label="Telefones"
                onClick={this.handleDialogOpen}
                //value={doisTelefones}
                value={telefonesDisplay}
                className={classes.telefonesDisplay}
                margin="normal"
                InputProps={{
                    readOnly: true,
                }}
            />
            {/* <Button onClick={this.handleDialogOpen}>Telefones</Button> */}
            <Dialog
                open={this.state.open}
                onClose={this.handleDialogClose}
                aria-labelledby="filter-dialog-title"
                scroll="body">
                <DialogTitle id="filter-dialog-title" className={classes.title}>Telefones</DialogTitle>

                <DialogContent>
                    <FieldArray
                        name="telefones"
                        render={
                            arrayHelpers => (
                                <div>
                                    {formik.values.telefones.map((telefone, index) => {
                                        const fieldName = `telefones.${index}`;
                                        return <div key={index} className={classes.telefone}>
                                            <FormControl required margin="normal" className={classes.formControl}>
                                                <InputLabel htmlFor="tipo-telefone">Tipo</InputLabel>
                                                <Select
                                                    value={formik.values.telefones[index].tipo}
                                                    onChange={(event) => this.handleChange(event, formik, `${fieldName}.tipo`)}
                                                    inputProps={{
                                                        name: `${fieldName}.tipo`,
                                                        id: `${fieldName}.tipo`
                                                    }}
                                                    onBlur={() => this.handleBlur(formik, `${fieldName}.tipo`)}
                                                    disabled={formik.isSubmitting}
                                                    error={Boolean(
                                                        getIn(formik.touched, `${fieldName}.tipo`) &&
                                                        getIn(formik.errors, `${fieldName}.tipo`)
                                                    )}
                                                >
                                                    <MenuItem value={"RESIDENCIAL"}>Residencial</MenuItem>
                                                    <MenuItem value={"FIXO"}>Fixo</MenuItem>
                                                    <MenuItem value={"FAX"}>Fax</MenuItem>
                                                    <MenuItem value={"MOVEL"}>Móvel</MenuItem>
                                                </Select>
                                                <FormHelperText
                                                    className={classes.mensagemErro}
                                                    error={Boolean(
                                                        getIn(formik.touched, `${fieldName}.tipo`) &&
                                                        getIn(formik.errors, `${fieldName}.tipo`)
                                                    )}>
                                                    {getIn(formik.errors, `${fieldName}.tipo`) &&
                                                        getIn(formik.touched, `${fieldName}.tipo`) && (
                                                            getIn(formik.errors, `${fieldName}.tipo`)
                                                        )}
                                                </FormHelperText>
                                            </FormControl>
                                            <TextField
                                                className={classes.formControl}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                    id: `${fieldName}.numero`,
                                                    name: `${fieldName}.numero`
                                                }}
                                                id={`${fieldName}.numero`}
                                                name={`${fieldName}.numero`}
                                                label="Número"
                                                value={formik.values.telefones[index].numero}
                                                error={Boolean(
                                                    getIn(formik.touched, `${fieldName}.numero`) &&
                                                    getIn(formik.errors, `${fieldName}.numero`)
                                                )}
                                                onChange={formik.handleChange}
                                                onBlur={() => this.handleBlur(formik, `${fieldName}.numero`)}
                                                disabled={formik.isSubmitting}
                                                margin="normal"
                                                helperText={getIn(formik.errors, `${fieldName}.numero`) &&
                                                    getIn(formik.touched, `${fieldName}.numero`) && (
                                                        getIn(formik.errors, `${fieldName}.numero`)
                                                    )}
                                                FormHelperTextProps={{ error: true }}
                                            />
                                            <TextField
                                                className={classes.formControl}
                                                id={`${fieldName}.ramal`}
                                                name={`${fieldName}.ramal`}
                                                label="Ramal"
                                                value={formik.values.telefones[index].ramal}
                                                error={Boolean(
                                                    getIn(formik.touched, `${fieldName}.ramal`) &&
                                                    getIn(formik.errors, `${fieldName}.ramal`)
                                                )}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                disabled={formik.isSubmitting}
                                                margin="normal"
                                                helperText={getIn(formik.errors, `${fieldName}.ramal`) &&
                                                    getIn(formik.touched, `${fieldName}.ramal`) && (
                                                        getIn(formik.errors, `${fieldName}.ramal`)
                                                    )}
                                                FormHelperTextProps={{ error: true }}
                                            />
                                            <IconButton
                                                className={classes.botaoRemover}
                                                onClick={() => this.removerTelefone(arrayHelpers, index)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    })}
                                    <Button onClick={() => this.adicionarTelefone(arrayHelpers)} color="primary">
                                        Adicionar
                                    </Button>
                                </div>
                            )
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDialogClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    }
};

export default withStyles(styles)(EmpresaTelefonesField);


