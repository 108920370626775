import React from 'react';
import { withStyles, ExpansionPanel, ExpansionPanelSummary, Typography, Divider, ExpansionPanelDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LogradouroField from './Fields/LogradouroField';
import NumeroField from './Fields/NumeroField';
import BairroField from './Fields/BairroField';
import ComplementoField from './Fields/ComplementoField';
import UfField from './Fields/UfField';
import MunicipiosField from './Fields/MunicipiosField';

const styles = (theme) => ({
    root: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        width: '100%',
    },
    column: {
        flexBasis: '33.33%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },

})

const empresaEnderecoPanel = (props) => {
    const { classes, formik } = props;
    const expandIcon = <ExpandMoreIcon/>;

    const enderecoLabel = 'Endereço';

    return (
        <div className={classes.root}>
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={expandIcon}>
                    <div className={classes.column}>
                        <Typography className={classes.heading}>{enderecoLabel}</Typography>
                    </div>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                    <LogradouroField formik={formik} />
                    <NumeroField formik={formik} />
                    <BairroField formik={formik} />
                    <ComplementoField formik={formik} />
                    <UfField formik={formik} />
                    <MunicipiosField formik={formik} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

export default withStyles(styles)(empresaEnderecoPanel);