import React, { Component } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import queryString from 'query-string';

import axios from '../../../axios-api';
import ResetSenhaForm from './ResetSenhaForm';

const styles = (theme) => ({
    resetSenhaComponent: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        height: '100vh',
    },
});

const initialValues = {
    NewPassword: '',
    ConfirmPassword: '',
}

class EsqueciSenha extends Component {
    state = {
        error: null,
    }

    handleSubmit = (values, addHandlers) => {
        var parsed = queryString.parse(this.props.location.search);
        const data = {
            Password: values.NewPassword,
            ConfirmPassword: values.ConfirmPassword,
            email: parsed.email,
            code: parsed.token
        };
        
        addHandlers(
            axios.post('/auth/resetpassword/', data)
                .then(() => {
                    this.props.history.push('/auth/resetconfirmado');
                })
                .catch(error => {
                    this.setState({ error: error.response.data.error_description });
                })
        );
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        
        let error = null;

        if (this.state.error)
            error =
                <div className={classes.errorBox}>
                    <Typography variant="subtitle1" color="secondary">
                        {this.state.error}
                    </Typography>
                </div>

        return (
            <div className={classes.resetSenhaComponent}>
                <ResetSenhaForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting }) => (
                        <React.Fragment>
                            {form}
                        </React.Fragment>
                    )}
                </ResetSenhaForm>

                {error}

            </div >
        )
    }
};

export default withStyles(styles)(EsqueciSenha);