import React, { useState, Fragment } from 'react';
import { Table, TableBody, TableRow, TableCell, TablePagination, Avatar, Typography, Menu, MenuItem, ListItemIcon, Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { FieldArray } from 'formik';
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks';
import CloseIcon from '@material-ui/icons/Close'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import AssignmentIcon from '@material-ui/icons/Assignment'
import EmailIcon from '@material-ui/icons/Email'

import { getSorting, getAmountOfRowsToRender } from '../../../../../shared/utilities';
import placeholder from '../../../../../content/images/avatar-placeholder.png'
import TableToolbar from './TableToolbar';
import GerenciarUsuariosTableHead from './GerenciarUsuariosTableHead';
import * as actions from '../../../../../store/actions';
import FullWidthSpinner from '../../../../../components/UI/Spinner/FullWidthSpinner';
import { ErrorPage } from '../../../../../components/ErrorBoundary/ErrorBoundary';


const dadosColuna = [
    { id: 'nome', numeric: false, padding: 'none', label: 'Nome' },
    { id: 'perfil', numeric: false, padding: 'none', label: 'Perfil' },
    { id: 'ultimoAcesso', numeric: false, padding: 'none', label: 'Último Acesso' },
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: 8,
        marginTop: theme.spacing(2),
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    table: {
        width: '100%',
    },
    tableWrapper: {
        overflowX: 'auto',
        margin: theme.spacing(1)
    },
    pagination: {
        display: 'inline-flex',
        width: '100%',
        flexDirection: 'row-reverse',
        padding: 15,
    },
    pagination__input: {
        paddingTop: 2,
    },
    nomeCell: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginLeft: 0,
        width: '3rem',
        height: '3rem',
    },
    ultimoAcesso: {
        width: 110,
    },
    perfil: {
        width: 180,
    },
    selectedRow: {
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
    progress: {
        margin: theme.spacing(2),
    },
    loading: {
        width: '100%',
        textAlign: 'center'
    },
}));

const GerenciarUsuariosTable = (props) => {
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('nome')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(getAmountOfRowsToRender())
    const [anchorEl, setAnchorEl] = useState(null);
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null)
    const classes = useStyles()

    const { loading, graphQlError, data } = useQuery(actions.GET_PERFIS);

    const handleRequestSort = (event, property) => {
        let newOrder = 'desc';

        if (orderBy === property && order === 'desc') {
            newOrder = 'asc';
        }
        setOrder(newOrder)
        setOrderBy(property)
    }

    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value)
    };

    const handleRowClicked = (event, index) => {
        setAnchorEl(event.currentTarget)
        setMouseX(event.clientX)
        setMouseY(event.clientY)
        setSelectedIndex(index)
    };

    const handleClose = () => {
        setConfirmationDialogOpen(false)
        setAnchorEl(null)
        setSelectedIndex(null)
        setSubmenuAnchorEl(null)
    };

    const handleSubmenuClicked = (event) => {
        setSubmenuAnchorEl(event.target)
    }

    const handleAlterarPerfil = (index, usuarios, perfilID) => {
        usuarios[index].perfilID = perfilID;
        handleClose();
    };

    const handleRemoverVinculo = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
        handleClose();
    }

    const handleResendEmailClicked = (index, usuarios) => {
        actions.RESEND_CONFIRMATION_EMAIL(usuarios[index].email);
        handleClose();
    }

    const handleOpenDialogClick = () => {
        setConfirmationDialogOpen(true)
    }

    const { onAdicionarClick } = props;

    const usuarios = props.formik.values.usuarios;

    const rowsPerPageOptions = [5, 10, 15]
    rowsPerPageOptions.push(rowsPerPage);
    rowsPerPageOptions.sort((a, b) => a - b);

    if (loading)
        return <FullWidthSpinner />;

    if (graphQlError)
        return <ErrorPage />

    const { perfis } = data;

    return (
        <div data-test="component-gerenciar-usuarios" className={classes.root}>
            <TableToolbar
                title={"Gerenciar usuários"}
                botaoAdicionarClick={onAdicionarClick} />
            <div className={classes.tableWrapper}><Table className={classes.table} aria-labelledby="tableTitle">
                <GerenciarUsuariosTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={usuarios.length}
                    columnData={dadosColuna}
                />
                <TableBody>
                    <FieldArray
                        name="usuarios"
                        render={
                            arrayHelpers => (
                                <Fragment>
                                    {usuarios
                                        .sort(getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((usuario, index) => {
                                            return <TableRow
                                                key={index}
                                                className={selectedIndex === index ? classes.selectedRow : null}
                                                hover
                                                onClick={event => handleRowClicked(event, index)}
                                                tabIndex={-1}
                                            >
                                                <TableCell component="th" scope="row" padding="none">
                                                    <div className={classes.nomeCell}>
                                                        <Avatar alt="Avatar"
                                                            className={classes.avatar}
                                                            src={usuario.foto ? usuario.foto : placeholder} />
                                                        <div>
                                                            <Typography variant="subtitle1">{usuario.nome}</Typography>
                                                            <Typography variant="caption" >{usuario.email}</Typography>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.perfil} component="th" scope="row" padding="none">
                                                    {perfis.map(perfil => perfil.id === usuario.perfilID ? perfil.nome : null)}
                                                </TableCell>
                                                <TableCell className={classes.ultimoAcesso} component="th" scope="row" padding="none">
                                                    {usuario.ultimoAcesso}
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    <Menu
                                        id="menu-usuario"
                                        anchorEl={anchorEl}
                                        anchorReference="anchorPosition"
                                        anchorPosition={{ top: mouseY, left: mouseX }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem
                                            onClick={() => handleResendEmailClicked(selectedIndex, usuarios)}>
                                            <ListItemIcon><EmailIcon /></ListItemIcon>
                                            Reenviar e-mail de confirmação
                                                </MenuItem>
                                        <MenuItem
                                            onClick={event => handleSubmenuClicked(event)}>
                                            <ListItemIcon><AssignmentIcon /></ListItemIcon>
                                            Alterar perfil <ArrowRightIcon />
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleOpenDialogClick}>
                                            <ListItemIcon><CloseIcon /></ListItemIcon>
                                            Remover vínculo
                                                </MenuItem>
                                    </Menu>
                                    <Menu
                                        id="submenu-perfis"
                                        anchorEl={submenuAnchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(submenuAnchorEl)}
                                        onClose={handleClose}
                                    >
                                        {perfis.map(perfil => (
                                            <MenuItem
                                                key={perfil.id}
                                                onClick={() => handleAlterarPerfil(selectedIndex, usuarios, perfil.id)}>
                                                {perfil.nome}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                    <Dialog
                                        open={confirmationDialogOpen}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title">
                                        <DialogTitle id="alert-dialog-title">{"Deseja realmente remover este vínculo?"}</DialogTitle>
                                        <DialogActions>
                                            <Button onClick={handleClose} color="primary" autoFocus>
                                                Cancelar
                                                </Button>
                                            <Button onClick={() => handleRemoverVinculo(selectedIndex, arrayHelpers)} color="primary">
                                                Confirmar
                                                </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Fragment>
                            )
                        }
                    />
                </TableBody>
            </Table>
            </div>
            <div className={classes.pagination}>
                <TablePagination
                    classes={{ input: classes.pagination__input }}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage={"Itens por página"}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    component="div"
                    count={usuarios.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Página Anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Página Seguinte',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div >
    );
}


export default GerenciarUsuariosTable;