import React, { Component } from 'react';
import { withStyles, Typography, Button, LinearProgress } from '@material-ui/core';

import LoginForm from './LoginForm';
import Background from '../../../content/images/login_background.jpg';
import axios from '../../../axios-api'
import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions'
import { empresaGestoraId } from '../../../shared/gestao';


const styles = theme => ({
    background: {
        background: `url(${Background}) center/cover`,
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        zIndex: -1,
    },
    loginComponent: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    loginBox: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    loginHeading: {
        background: 'white',
        width: 400,
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
    },
    loginHeading__title: {
        fontWeight: 300,
    },
    loginForm: {
        background: '#384f7d',
        width: 400,
    },
    blueBox: {
        width: 400,
        background: '#384f7d',
        height: 35
    },
    inputContainer: {
        paddingBottom: 5,
    },
    errorBox: {
        paddingTop: 8,
        paddingLeft: 8,
        paddingRight: 8,
        width: 400,
        background: '#384f7d',
        color: 'white',
        textAlign: 'center'
    },
    linearProgress: {
        position: 'absolute',
        width: 400,
    },
});

const initialValues = {
    email: '',
    senha: '',
    autoLogin: true,
}

class Login extends Component {
    state = {
        error: null,
        errorType: null,
        loading: false,
    }

    handleSubmit = (values, addHandlers, location) => {
        const loginData = {
            username: values.email,
            password: values.senha
        };
        addHandlers(
            axios.post('/auth/login/', loginData)
                .then(response => {
                    const user = response.data;
                    actions.SET_AUTH_HEADERS(user.token);
                    actions.SET_EMPRESA_HEADER(empresaGestoraId);

                    if (!values.autoLogin) {
                        window.onbeforeunload = function () {
                            localStorage.removeItem('user');
                            return '';
                        };
                    }

                    apolloClientProvider.client
                        .mutate({ mutation: actions.SET_AUTH, variables: { input: { ...user, autoLogin: values.autoLogin, empresaId: empresaGestoraId, empresaNome: "ZEUSDEV TECNOLOGIA LTDA ME", __typename: "Auth", } } })
                        .then(({ data }) => {
                            actions.CHECK_AUTH_TIMEOUT(data.setAuth.auth.expires);
                        });
                    apolloClientProvider.client.mutate({ mutation: actions.SET_STARTING_PAGE, variables: { input: location, __typename: "StartingPage", } })
                })
                .catch(error => {
                    let errorMessage, errorType;
                    if (error.message === 'Network Error') {
                        errorType = null;
                        errorMessage = 'Problemas de comunicação com o servidor'
                    }
                    else if (error.response &&
                        error.response.data &&
                        error.response.data.error === 'email_not_confirmed') {
                        errorType = null;
                        errorMessage = error.response.data.error_description;
                    }
                    else if (error.response &&
                        error.response.data &&
                        error.response.data.error_description === 'E-mail ou Senha incorretos, verifique as credenciais e tente novamente.') {
                        errorMessage = error.response.data.error_description;
                        errorType = 'SENHAINCORRETA';
                    }
                    else if (error.response &&
                        error.response.status &&
                        error.response.status === 401) {
                        errorMessage = 'Usuário não autorizado';
                        errorType = null;
                    }
                    else {
                        errorType = null;
                        errorMessage = error.message;
                    }

                    this.setState({ error: errorMessage, errorType });
                    throw error;
                })
        );
    };

    handleRedefinirSenha = (values) => {
        this.setState({ loading: true });
        axios.post(`/auth/requestresetpassword?email=${values.email}`)
            .then(() => {
                actions.OPEN_SNACKBAR('E-mail de redefinição de senha enviado!');
                this.setState({ error: null, errorType: null });
                this.setState({ loading: false });
            })
            .catch(() => {
                actions.OPEN_SNACKBAR('Erro ao solicitar redefinição de senha.', true)
                this.setState({ loading: false });
            })
    }

    render() {
        let { from } = this.props.location.state || { from: { pathname: "/" } };

        const { classes } = this.props;

        return (
            <div data-test='component-login' className={classes.loginComponent}>
                <div className={classes.background} />
                <div className={classes.loginBox}>
                    <div className={classes.loginHeading}>
                        <Typography className={classes.loginHeading__title} variant="h2" align="center">
                            Login
                    </Typography>
                    </div>
                    <div className={classes.loginForm}>
                        <div className={classes.inputContainer}>
                            <LoginForm
                                onSubmit={(values, addHandlers) => this.handleSubmit(values, addHandlers, from)}
                                initialValues={initialValues}
                            >
                                {({ form, isDirty, isSubmitting, values }) => (
                                    <React.Fragment>
                                        {form}

                                        {this.state.loading && <LinearProgress className={classes.linearProgress} />}
                                        {(this.state.error) &&
                                            <div className={classes.errorBox}>
                                                <Typography variant="subtitle1" color="inherit">
                                                    {this.state.error}
                                                </Typography>
                                                {this.state.errorType === 'SENHAINCORRETA' &&
                                                    <Typography variant="subtitle1" color="inherit">
                                                        <Button
                                                            onClick={() => this.handleRedefinirSenha(values)}
                                                            color="inherit"
                                                            aria-label="Redefinir Senha">
                                                            Redefinir senha
                                                    </Button>
                                                    </Typography>
                                                }
                                            </div>}
                                    </React.Fragment>
                                )}
                            </LoginForm>
                        </div>
                    </div>

                    <div className={classes.blueBox} />

                </div>
            </div>
        );
    }

};

export default withStyles(styles)(Login);