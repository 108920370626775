import React, { Fragment } from "react";
import { Avatar, Drawer, Divider, List, ListItem, ListItemText, Menu, ListItemIcon, MenuItem, ListItemAvatar } from '@material-ui/core';
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import LockIcon from '@material-ui/icons/Lock'
import BusinessIcon from '@material-ui/icons/Business'
import PersonIcon from '@material-ui/icons/Person'

import placeholder from '../../../content/images/avatar-placeholder.png'
import iconeZeusBranco from '../../../content/images/iconeZeus-branco.svg';
import NavigationItem from "../../NavigationItem/NavigationItem";
import * as actions from '../../../store/actions'

const useStyles = makeStyles(theme => ({
    cinzaMenu: {
        color: '#f2f2f29e'
    },
    avatarDiv: {
        display: 'inline-block',
        width: '100%',
        verticalAlign: 'middle'
    },
    avatar: {
        display: 'inline-block',
        width: '25%',
        height: '25%'
    },
    avatar__nomeUsuario: {
        lineHeight: '60px',
        paddingRight: 20,
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    drawerPaper: {
        width: 200,
        backgroundColor: theme.palette.primary.main,
    },
    sidedrawer__lista: {
        paddingTop: 0,
        paddingBottom: 0
    },
    contato: {
        color: '#f2f2f29e',
        textAlign: 'center',
        fontSize: 9.5,
        bottom: 0,
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 178
    },
    guttersPadding16: {
        paddingLeft: 16,
        paddingRight: 16
    },
    avatarContainer: {
        padding: '20px 10px'
    },
}));

const SideDrawer = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const history = useHistory();
    const { loading, error, data } = useQuery(actions.GET_AUTH);

    if (loading) return null;
    if (error) return error;

    const { auth } = data;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAlterarSenhaClicked = () => {
        setAnchorEl(null)
        history.push('/auth/alterarsenha');
    }

    const logout = () => {
        actions.LOGOUT();
    }

    const foto = auth.foto ? auth.foto : placeholder;

    const avatar = (
        <React.Fragment>
            <ListItem
                button
                className={classes.avatarContainer}
                onClick={handleClick}>
                <ListItemAvatar>
                    <Avatar
                        alt="Avatar"
                        //className={classes.avatar}
                        src={foto}>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={`Olá, ${auth.nome}!`}
                    classes={{ primary: classes.cinzaMenu }} />
            </ListItem>

            <Menu
                id="menu-usuario"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}                    >
                <MenuItem
                    onClick={handleAlterarSenhaClicked}>
                    <ListItemIcon><LockIcon /></ListItemIcon>
                    Alterar senha
                </MenuItem>
                <MenuItem
                    onClick={logout}>
                    <ListItemIcon><CloseIcon /></ListItemIcon>
                    Sair
                </MenuItem>
            </Menu>
        </React.Fragment>

    );

    const listContent = (
        <Fragment>
            <NavigationItem link="/empresas" icon={<BusinessIcon />} texto="Empresas" />
            <Divider />
            <NavigationItem link="/usuarios" icon={<PersonIcon />} texto="Usuários" />
            {/* <Divider />
                <NavigationItem link="/configuracoes" icon={<TuneIcon />} texto="Configurações" /> */}
        </Fragment>
    );

    const contato = (
        <div className={classes.contato}>
            <img src={iconeZeusBranco} alt="IconeZeus" />
            <p>(79) 3432-8350, 99912-8350, 99913-8350</p>
            <p>contato@zeusautomacao.com.br</p>
            <p>Av. Otoniel Dórea, 455, Itabaiana - Sergipe</p>
        </div>
    );

    return (
        <Drawer data-test="component-side-drawer" classes={{ paper: classes.drawerPaper }}
            variant="permanent">
            <List className={classes.sidedrawer__lista}>
                {avatar}
                <Divider />
                {listContent}
            </List>
            <Divider />
            {contato}
        </Drawer>
    )
}

export default SideDrawer;