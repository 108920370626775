import React, { Component } from "react";
import { Formik, Form, getIn } from "formik";
import PropTypes from 'prop-types';
import { withStyles, IconButton, Typography, Fab } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import Yup from '../../../yup';
import * as cores from '../../../shared/cores';
import EmpresaTipoField from "./Fields/EmpresaTipoField";
import EmpresaCpfCnpjField from "./Fields/EmpresaCpfCnpjField";
import { validateCpfCnpj } from "../../../shared/utilities";
import EmpresaNomeField from "./Fields/EmpresaNomeField";
import EmpresaNomeFantasiaField from "./Fields/EmpresaNomeFantasiaField";
import EmpresaRgieField from "./Fields/EmpresaRgieField";
import EmpresaUfField from "./Fields/EmpresaUfField";
import EmpresaEmailField from "./Fields/EmpresaEmailField";
import EmpresaTelefonesField from "./Fields/EmpresaTelefonesField";
import EmpresaCEPField from "./Fields/EmpresaCEPField";
import EmpresaEnderecoPanel from "./Panels/EnderecoPanel/EmpresaEnderecoPanel";
import GerenciarUsuariosTable from "./Panels/GerenciarUsuariosTable/GerenciarUsuariosTable";
import AdicionarUsuariosDialog from "./AdicionarUsuariosDialog";
import EmpresaValidadeField from './Fields/EmpresaValidadeField'

const styles = theme => ({
    form: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    buttonConfirmContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        margin: 20,
        marginTop: 5,
        width: '100%',
    },
    buttonClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 20,
        width: 56,
        height: 56,
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        minHeight: 64,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
})

function cpfCnpj(message = "CPF/CNPJ inválido") {
    return this.test({
        message,
        name: 'CpfCnpj',
        exclusive: true,
        test: function (value) { return (validateCpfCnpj(value) || value == null || value === ""); }
    })
}

Yup.addMethod(Yup.string, 'CpfCnpj', cpfCnpj);

const EmpresaSchema = Yup.object().shape({
    tipoPessoa: Yup.string()
        .required("Requerido"),
    cpfCnpj: Yup.string()
        .required("Requerido")
        .CpfCnpj(),
    nome: Yup.string()
        .required("Requerido"),
    nomeFantasia: Yup.string(),
    rgie: Yup.string()
        .required("Requerido"),
    uf: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).required("Requerido"),
    email: Yup.string()
        .email("E-mail inválido")
        .required("Requerido"),
    telefones: Yup.array()
        .of(Yup.object().shape({
            id: Yup.number(),
            tipo: Yup.string(),
            numero: Yup.string()
                .min(10, "O telefone deve ter ao menos 10 dígitos"),
            ramal: Yup.string(),
        })
        ),
    cep: Yup.string()
        .required("Requerido"),
    logradouro: Yup.string()
        .required("Requerido"),
    numero: Yup.string()
        .required("Requerido"),
    bairro: Yup.string()
        .required("Requerido"),
    complemento: Yup.string(),
    validade: Yup.date().notRequired().nullable(),
    uf_endereco: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).required("Requerido"),
    municipio: Yup.object().shape({
        value: Yup.number(),
        label: Yup.string()
    }).required("Requerido"),


});

export class EmpresaForm extends Component {
    state = {
        adicionarUsuariosDialogOpen: false,
    }

    handleAdicionarUsuariosDialogOpen = () => {
        this.setState({ adicionarUsuariosDialogOpen: true });
    }

    handleAdicionarUsuariosDialogClose = () => {
        this.setState({ adicionarUsuariosDialogOpen: false });
    }

    render() {
        const { classes } = this.props;
        return (
            <Formik
                initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={EmpresaSchema}
                onSubmit={(values, formikBag) => {
                    const addHandlers = promise =>
                        promise.then(
                            result => {
                                formikBag.resetForm();
                                formikBag.setSubmitting(false);

                                return result;
                            },
                            error => {
                                formikBag.setSubmitting(false);
                                formikBag.setErrors(error.validationErrors);

                                throw error;
                            }
                        );
                    return this.props.onSubmit(values, addHandlers);
                }}>
                {formikProps => {
                    const form = (
                        <React.Fragment>
                            <div className={classes.title}>
                                <Typography color="inherit" variant="h6">
                                    Cliente
                                    </Typography>
                            </div>
                            <Form className={classes.form}>
                                <IconButton
                                    className={classes.buttonClose}
                                    // color="primary"
                                    aria-label="Cancelar"
                                    onClick={this.props.handleClose}>
                                    <CloseIcon />
                                </IconButton>
                                <EmpresaTipoField formik={formikProps} />
                                <EmpresaCpfCnpjField formik={formikProps} />
                                <EmpresaNomeField formik={formikProps} />
                                <EmpresaNomeFantasiaField formik={formikProps} />
                                <EmpresaRgieField formik={formikProps} />
                                <EmpresaUfField formik={formikProps} />
                                <EmpresaEmailField formik={formikProps} />
                                <EmpresaTelefonesField getIn={getIn} formik={formikProps} />
                                <EmpresaCEPField formik={formikProps} />
                                <EmpresaValidadeField formik={formikProps} />
                                <EmpresaEnderecoPanel formik={formikProps} />
                                <GerenciarUsuariosTable
                                    formik={formikProps}
                                    onAdicionarClick={this.handleAdicionarUsuariosDialogOpen} />
                                <AdicionarUsuariosDialog
                                    open={this.state.adicionarUsuariosDialogOpen}
                                    onClose={this.handleAdicionarUsuariosDialogClose}
                                    getIn={getIn}
                                    formik={formikProps} />
                                <div className={classes.buttonConfirmContainer}>
                                    <Fab
                                        disabled={formikProps.isSubmitting}
                                        type="submit"
                                        color="primary"
                                        aria-label="Adicionar">
                                        <CheckIcon />
                                    </Fab>
                                </div>
                            </Form>
                        </React.Fragment>
                    );

                    return this.props.children({
                        form,
                        isDirty: formikProps.dirty,
                        isSubmitting: formikProps.isSubmitting,
                        handleSubmit: formikProps.handleSubmit,
                        handleCancel: formikProps.resetForm,
                    });
                }}
            </Formik>
        );
    }
}

EmpresaForm.propTypes = {
    initialValues: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
}

export default withStyles(styles)(EmpresaForm);