import gql from 'graphql-tag';

export const GET_USUARIOS = gql`
{
  usuarios {
      id
      nome
      email
      departamento
  }
}
`;

export const GET_USUARIOS_CLIENTE = gql`
query cliente($id: Int){
    cliente(cliente: { id: $id }){
        usuarios {
            usuario{
                id
                nome
                email
                username
                foto
            }
            perfil{
                id
                nome
            }
        }
    }
}
`;

export const GET_USUARIO = gql`
    query usuario($id: String, $email: String){
        usuario(usuario: {id: $id, email: $email}){
            id
            nome
            cargo
            departamento
            email
            foto
            username
            clienteusuarios {
                clienteid
                perfil {
                    id
                    nome
                }
            }
        }
    }

`;

export const SET_USUARIO = gql`
mutation adicionarOuAtualizarUsuario($clienteusuario: ClienteUsuarioInput!){
    adicionarOuAtualizarUsuario(clienteusuario: $clienteusuario){
        usuario {
            nome
        }
    }
  }
`;

