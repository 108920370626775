import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';

import apolloClientProvider from './apolloClientProvider';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#21486d'
        }
    }
});

const app = (
    <ApolloProvider client={apolloClientProvider.client}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter >
    </ApolloProvider >
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
