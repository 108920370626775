import React from 'react';
import { getIn } from 'formik'
import { TextField, withStyles } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { fetchFromObject } from '../../../../shared/utilities';

function MaskCEP(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            showMask
        />
    );
}
MaskCEP.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
        display: 'flex',
    }
});

const empresaCEPField = (props) => {
    const { formik, classes } = props;

    const nomeCampo = "cep", label = 'CEP'

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    return (
        <React.Fragment>
            <TextField
                InputProps={{ inputComponent: MaskCEP }}
                className={classes.textField}
                required
                id={nomeCampo}
                label={label}
                value={fetchFromObject(formik.values, nomeCampo)}
                error={Boolean(touched && error)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default withStyles(styles)(empresaCEPField);
