import gql from 'graphql-tag';

import apolloClientProvider from '../../apolloClientProvider';
import axios from '../../axios-api';
import { SET_STARTING_PAGE } from './startingPage';
import * as actions from './';

export const GET_AUTH = gql`
{
  auth @client {
    id
    username
    nome
    foto
    expires
    token
    empresaId
    empresaNome
    autoLogin
    }
}
`;

export const SET_AUTH = gql`
    mutation setAuth($input: User!) {
        setAuth(input: $input) @client
    }
`;

export const CHECK_AUTH = (location = false) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.autoLogin)
        LOGOUT();
    else {
        const expirationDate = new Date(user.expires);
        if (expirationDate < new Date()) {
            LOGOUT();
        }
        else {
            const client = apolloClientProvider.client;
            client
                .mutate({ mutation: SET_AUTH, variables: { input: { ...user, __typename: "Auth", } } })
                .then(({ data }) => {
                    CHECK_AUTH_TIMEOUT(data.setAuth.auth.expires);
                    client.mutate({ mutation: SET_STARTING_PAGE, variables: { input: location , __typename: "StartingPage",} })
                })
            SET_AUTH_HEADERS(user.token);
            SET_EMPRESA_HEADER(user.empresaId);
            if(!user.autoLogin){
                window.onbeforeunload = function() {
                    localStorage.removeItem('user');
                    return '';
                  };
            }
        }
    }
};

export const CHECK_AUTH_TIMEOUT = (expirationTime) => {
    setTimeout(() => {
        LOGOUT();
    }, (new Date(expirationTime) - new Date()));
}

export const SET_AUTH_HEADERS = (token) => {
    axios.defaults.headers = { 'Authorization': 'Bearer ' + token };
}

export const SET_EMPRESA_HEADER = (empresaId) => {
    axios.defaults.headers.Empresa = empresaId;
}

export const LOGOUT = () => {
    axios.post('/auth/logout')
        .then(() => {
            localStorage.removeItem('user');
            apolloClientProvider.client.resetStore();
            window.location.reload();
        })
}

export const RESEND_CONFIRMATION_EMAIL = (email) => {
    axios.post(`/auth/sendconfirm?email=${email}`)
        .then((response) => {
            actions.OPEN_SNACKBAR(response.data)
        })
}