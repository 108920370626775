import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link';
import { buildAxiosFetch } from 'axios-fetch';

import axios from './axios-api';
import * as storeResolvers from './store/resolvers';
import { errorLink } from './shared/apollo';

export const defaultStore = {
    auth: null,
    snackbar: {
        open: false,
        message: null,
        error: false,
        noAutoClose: false,
        __typename: 'Snackbar',
    },
    startingPage: false,
};

const mutationResolvers = {
    Mutation: storeResolvers
}

export class ApolloClientProvider {
    /**
     * @constructor - Construtor do ApolloClient
     * @param {object} defaults - Default store do clientState
     * @param {object} resolvers - Resolvers do clientState
     */
    constructor(defaults = defaultStore,
        resolvers = mutationResolvers) {

        this.cache = new InMemoryCache();

        const httpLink = new HttpLink({
            uri: '/graphql',
            credentials: 'include',
            fetch: buildAxiosFetch(axios),
        });

        const link = ApolloLink.from([
            errorLink,
            httpLink
        ]);

        this.defaultStore = defaults;

        this.client = new ApolloClient({
            link,
            cache: this.cache,
            resolvers
        });

        this.writeCacheDefaults();

        this.client.onResetStore(this.writeCacheDefaults);
    }

    writeCacheDefaults = () => {
        this.client.cache.writeData({
            data: this.defaultStore
        })
    }
}

export default new ApolloClientProvider()