import React, {  useState } from 'react';
import { Table, TableBody, TableRow, TableCell, TablePagination, Tooltip, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import AddIcon from '@material-ui/icons/Add'

import { getSorting, getAmountOfRowsToRender } from '../../shared/utilities';
import * as actions from '../../store/actions';
import Spinner from '../../components/UI/Spinner/FullWidthSpinner';
import CommonTableToolbar from '../../components/UI/CommonTableToolbar/CommonTableToolbar';
import CommonTableHead from '../../components/UI/CommonTableHead/CommonTableHead';
import * as celltypes from '../../shared/celltypes';
import UsernameTableData from '../../components/UI/UsernameTableData/UsernameTableData';
import { ErrorPage } from '../../components/ErrorBoundary/ErrorBoundary';

const dadosColuna = [
    { id: 'nome', type: celltypes.USER, numeric: false, padding: 'default', label: 'Nome' },
    { id: 'email', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'E-mail' },
    { id: 'departamento', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'Departamento' },
    { id: 'perfil', type: celltypes.DEFAULT, numeric: false, padding: 'none', label: 'Perfil' },
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        height: '100%'
    },
    table: {
        width: '100%',
    },
    tableWrapper: {
        overflowX: 'auto',
        height: 'calc(100% - 140px);'
    },
    pagination: {
        display: 'inline-flex',
        width: '100%',
        flexDirection: 'row-reverse',
        padding: 20,
        paddingTop: 0,
    },
    pagination__input: {
        paddingTop: 2,
    },
    row: {
        cursor: 'pointer'
    }
}));

export const Usuarios = () => {
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('id')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(getAmountOfRowsToRender())
    const history = useHistory()
    const classes = useStyles()

    const { loading, error, data } = useQuery(actions.GET_USUARIOS_CLIENTE, {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
            id: 1
        }
    })

    const handleRequestSort = (event, property) => {
        let newOrder = 'desc';

        if (orderBy === property && order === 'desc') {
            newOrder = 'asc';
        }
        setOrder(newOrder)
        setOrderBy(property)
    }

    const handleClick = (event, id) => {
        history.push('/usuarios/novo/' + id);
    };

    const handleChangePage = (event, page) => {
        setPage(page)
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(event.target.value)
    };

    const formatTableData = data => {
        return data.map(u => {
            const { usuario, perfil } = u;
            return {
                id: usuario.id,
                nome: usuario.nome,
                email: usuario.email,
                departamento: usuario.departamento,
                perfil: perfil.nome,
            }
        })
    }

    const linkNovoUsuario = React.forwardRef((props, ref) => <Link to="/usuarios/novo" {...props} ref={ref} />);

    const filterDialog = null;

    const rowsPerPageOptions = [5, 10, 15]
    rowsPerPageOptions.push(rowsPerPage);
    rowsPerPageOptions.sort((a, b) => a - b);

    if (loading)
        return <Spinner />;

    if (error)
        return <ErrorPage />

    const { cliente } = data;

    const tableData = formatTableData(cliente.usuarios);

    return (
        <div data-test="component-usuarios" className={classes.paper}>
            <CommonTableToolbar
                title={"Usuários"}
                filterDialog={filterDialog} />
            <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                    <CommonTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={tableData.length}
                        columnData={dadosColuna}
                    />
                    <TableBody>
                        {tableData
                            .sort(getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(n => {
                                return (
                                    <TableRow
                                        hover
                                        className={classes.row}
                                        onClick={event => handleClick(event, n.id)}
                                        tabIndex={-1}
                                        key={n.id}
                                    >
                                        {
                                            dadosColuna.map(coluna => {
                                                switch (coluna.type) {
                                                    case (celltypes.DEFAULT):
                                                        return (<TableCell
                                                            key={n.id + n[coluna.id]}
                                                            component="th"
                                                            scope="row"
                                                            padding={coluna.padding}>
                                                            {n[coluna.id]}
                                                        </TableCell>)
                                                    case (celltypes.NUMERIC):
                                                        return (<TableCell
                                                            key={n.id + n[coluna.id]}
                                                            padding={coluna.padding}
                                                            align='right'>
                                                            {n[coluna.id]}
                                                        </TableCell>)
                                                    case (celltypes.USER):
                                                        return <UsernameTableData
                                                            usuario={n}
                                                            padding={coluna.padding}
                                                            key={n.id + n[coluna.id]} />
                                                    default:
                                                        return (<TableCell
                                                            key={n.id + n[coluna.id]}
                                                            component="th"
                                                            scope="row"
                                                            padding={coluna.padding}>
                                                            {n[coluna.id]}
                                                        </TableCell>)
                                                }
                                            })
                                        }
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.pagination}>
                <Tooltip title="Novo">
                    <Fab
                        component={linkNovoUsuario}
                        color="primary"
                        aria-label="Adicionar">
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <TablePagination
                    classes={{ input: classes.pagination__input }}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage={"Itens por página"}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Página Anterior',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Página Seguinte',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </div >
    );
}

export default Usuarios;