import React from 'react';
import { getIn } from 'formik'
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fetchFromObject } from '../../../../shared/utilities';

const useStyles = makeStyles(theme => ({
    textField: {
        margin: theme.spacing(1),
        width: 550,
        display: 'flex',
    }
}));

const UsuarioSenhaField = (props) => {
    const classes = useStyles()
    const { formik } = props;

    const nomeCampo = "senha", label = 'Senha'

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                type="password"
                id={nomeCampo}
                label={label}
                inputProps={{ autoComplete: "new-password" }}
                value={fetchFromObject(formik.values, nomeCampo)}
                error={Boolean(touched && error)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{ error: true }}
            />

        </React.Fragment>
    )
}

export default UsuarioSenhaField;
