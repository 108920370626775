import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import EmpresaForm from './Form/EmpresaForm';
import * as actions from '../../store/actions';
import { somenteNumeros } from '../../shared/utilities';
import apolloClientProvider from '../../apolloClientProvider';
import { listaUF } from '../../shared/utilities';

const styles = {

}

const initialValues = {
    id: undefined,
    tipoPessoa: "JURIDICA",
    cpfCnpj: "__.___.___/____-__",
    nome: '',
    nomeFantasia: '',
    rgie: '',
    uf: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    email: '',
    telefones: [],
    cep: "_____-___",
    enderecoId: undefined,
    logradouro: '',
    numero: '',
    bairro: '',
    complemento: undefined,
    uf_endereco: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    municipio: { value: null, label: null },
    usuarios: [],
    validade: null
}

class PureEmpresasCreate extends Component {
    state = {
        errors: null
    }

    resetForm = null;

    componentDidMount() {
        const id = this.props.match.params.id;
        if (id) {
            apolloClientProvider.client.query({ query: actions.GET_CLIENTE, variables: { id: id }, fetchPolicy: "network-only" })
                .then(({ data: { cliente } }) => {
                    const telefones = cliente.telefones.map(num => ({
                            id: num.telefone.id,
                            numero: num.telefone.ddi + num.telefone.numero,
                            tipo: num.telefone.tipotelefone,
                            ramal: num.telefone.ramal
                        }));
                    const usuarios = cliente.usuarios.map(u => ({
                            id: u.usuario.id,
                            nome: u.usuario.nome,
                            perfilID: u.perfil.id,
                            ultimoAcesso: u.usuario.ultimoacesso,
                            email: u.usuario.email,
                            foto: u.usuario.foto,
                            username: u.usuario.username
                        }));
                    const uf = { value: cliente.ufemissorrgieid, label: listaUF.find(e => e.id === cliente.ufemissorrgieid).sigla };

                    const estadoId = cliente.endereco.municipio.estado.id;
                    const uf_endereco = { value: estadoId, label: cliente.endereco.municipio.estado.sigla };

                    const municipio = {value: cliente.endereco.municipio.id, label: cliente.endereco.municipio.nome}

                    const resetData = {
                        id: cliente.id,
                        tipoPessoa: cliente.tipopessoa ? cliente.tipopessoa : initialValues.tipoPessoa,
                        cpfCnpj: cliente.cpfcnpj ? cliente.cpfcnpj : initialValues.cpfCnpj,
                        nome: cliente.nome ? cliente.nome : initialValues.nome,
                        nomeFantasia: cliente.nomefantasia ? cliente.nomefantasia : initialValues.nomeFantasia,
                        rgie: cliente.rgie ? cliente.rgie : initialValues.rgie,
                        uf: cliente.ufemissorrgieid ? uf : initialValues.uf,
                        email: cliente.email ? cliente.email : initialValues.email,
                        telefones: cliente.telefones ? telefones : initialValues.telefones,
                        cep: cliente.endereco.cep ? cliente.endereco.cep : initialValues.cep,
                        enderecoId: cliente.endereco.id ? cliente.endereco.id : initialValues.enderecoId,
                        logradouro: cliente.endereco.logradouro ? cliente.endereco.logradouro : initialValues.logradouro,
                        numero: cliente.endereco.numero ? cliente.endereco.numero : initialValues.numero,
                        bairro: cliente.endereco.bairro ? cliente.endereco.bairro : initialValues.bairro,
                        complemento: cliente.endereco.complemento ? cliente.endereco.complemento : initialValues.complemento,
                        uf_endereco: cliente.endereco.municipio.estado ? uf_endereco : initialValues.uf,
                        municipio: cliente.endereco.municipio ? municipio : initialValues.municipio,
                        usuarios: cliente.usuarios ? usuarios : initialValues.usuarios,
                        validade: cliente.validade ? cliente.validade : initialValues.validade
                    }
                    this.resetForm({values: resetData});
                });
        }
    }

    handleSubmit = (values, addHandlers) => {
        const id = this.props.match.params.id;

        const submitData = {
            id: id ? parseInt(id, 10) : undefined,
            tipopessoa: values.tipoPessoa,
            cpfcnpj: somenteNumeros(values.cpfCnpj),
            nome: values.nome,
            email: values.email,
            nomefantasia: values.nomeFantasia,
            rgie: values.rgie,
            ufemissorrgieid: values.uf.value,
            validade: values.validade,
            endereco: {
                id: values.enderecoId,
                logradouro: values.logradouro,
                numero: values.numero,
                cep: somenteNumeros(values.cep),
                bairro: values.bairro,
                complemento: values.complemento ? values.complemento : undefined,
                municipioid: values.municipio.value,
            },
            usuarios: values.usuarios.map(u => {
                return {
                    usuario: {
                        email: u.email,
                    },
                    perfilid: u.perfilID
                }
            }),
            telefones: values.telefones.map(t => {
                return {
                    telefone: {
                        ddi: t.numero.slice(0, 2),
                        id: t.id,
                        numero: t.numero.slice(2, t.numero.length),
                        tipotelefone: t.tipo
                    }
                }
            }),
        }

        addHandlers(
            apolloClientProvider.client.mutate(
                {
                    mutation: actions.SET_EMPRESA,
                    variables: { cliente: submitData },
                    refetchQueries: [{ query: actions.GET_EMPRESAS }],
                })
                .then(({ data }) => {
                    actions.OPEN_SNACKBAR('Operação realizada com sucesso!');
                    this.props.history.push('/empresas');
                })
        )
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <EmpresaForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting, handleCancel }) => (
                        <React.Fragment>
                            {this.resetForm = handleCancel}
                            {form}
                        </React.Fragment>
                    )}
                </EmpresaForm>
            </div >
        );
    };
};

export default withStyles(styles)(withRouter(PureEmpresasCreate));