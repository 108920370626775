import React, { Component } from "react";
import { Formik, Form } from "formik";
import PropTypes from 'prop-types';
import { withStyles, IconButton, Fab } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import Yup from '../../../yup';
import UsuarioNomeField from "./Fields/UsuarioNomeField";
import UsuarioCargoField from "./Fields/UsuarioCargoField";
import UsuarioDepartamentoField from "./Fields/UsuarioDepartamentoField";
import UsuarioEmailField from "./Fields/UsuarioEmailField";
import UsuarioSenhaField from "./Fields/UsuarioSenhaField";
import UsuarioFotoField from "./Fields/UsuarioFotoField";
import UsuarioConfirmacaoSenha from "./Fields/UsuarioConfirmacaoSenha";
import UsuarioPerfilField from "./Fields/UsuarioPerfilField";


const styles = theme => ({
    gridContainer: {
        display: 'grid',
        paddingTop: 100,
        gridGap: '30px',
        gridTemplateColumns: '0px auto auto'
    },
    column1: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: theme.spacing(4),
        gridColumnStart: 2,
        gridColumnEnd: 2,
    },
    column2: {
        gridColumnStart: 3,
        gridColumnEnd: 3,
    },
    buttonConfirm: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: 20
    },
    buttonClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 20,
        width: 56,
        height: 56
    },
})

const UsuarioSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string()
        .required(),
    cargo: Yup.string(),
    departamento: Yup.string(),
    email: Yup.string()
        .required()
        .email(),
    redefinirSenha: Yup.boolean(),
    senha: Yup.string()
        .when('id', (id, schema) => Boolean(id) ? schema.notRequired() : schema.required().min(6)),
    confirmacaoSenha: Yup.string()
        .when('senha', {
            is: value => value && (value.length !== 0),
            then: Yup.string().required().min(6).oneOf([Yup.ref('senha')], 'A senha e confirmação devem ser iguais'),
            otherwise: Yup.string().notRequired().min(6).oneOf([Yup.ref('senha')], 'A senha e confirmação devem ser iguais'),
        }),
    perfil: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required()
    }),
    foto: Yup.string()
});

export class UsuarioForm extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Formik
                initialValues={this.props.initialValues}
                enableReinitialize={true}
                validationSchema={UsuarioSchema}
                onSubmit={(values, formikBag) => {
                    const addHandlers = promise =>
                        promise.then(
                            result => {
                                formikBag.resetForm();
                                formikBag.setSubmitting(false);

                                return result;
                            },
                            error => {
                                formikBag.setSubmitting(false);
                                formikBag.setErrors(error.validationErrors);

                                throw error;
                            }
                        );
                    return this.props.onSubmit(values, addHandlers);
                }}>
                {formikProps => {
                    const form = (
                        <React.Fragment>
                            <Form>
                                <div className={classes.gridContainer}>
                                    <div className={classes.column1}>
                                        <UsuarioFotoField formik={formikProps} />
                                    </div>
                                    <div className={classes.column2}>
                                        <IconButton
                                            className={classes.buttonClose}
                                            // color="primary"
                                            aria-label="Cancelar"
                                            onClick={this.props.handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                        <UsuarioNomeField formik={formikProps} />
                                        <UsuarioCargoField formik={formikProps} />
                                        <UsuarioDepartamentoField formik={formikProps} />
                                        <UsuarioEmailField formik={formikProps} />
                                        {/* <UsuarioRedefinirSenhaField formik={formikProps} /> */}
                                        <UsuarioSenhaField formik={formikProps} />
                                        <UsuarioConfirmacaoSenha formik={formikProps} />
                                        <UsuarioPerfilField formik={formikProps} />
                                        <Fab
                                            className={classes.buttonConfirm}
                                            type="submit"
                                            color="primary"
                                            aria-label="Adicionar">
                                            <CheckIcon />
                                        </Fab>
                                    </div>
                                </div>
                            </Form>
                        </React.Fragment>
                    );

                    return this.props.children({
                        form,
                        isDirty: formikProps.dirty,
                        isSubmitting: formikProps.isSubmitting,
                        setFieldValue: formikProps.setFieldValue,
                        setFieldError: formikProps.setFieldError,
                        setFieldTouched: formikProps.setFieldTouched,
                        handleSubmit: formikProps.handleSubmit,
                        handleCancel: formikProps.resetForm,
                    });
                }}
            </Formik>
        );
    }
}

UsuarioForm.propTypes = {
    initialValues: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
}

export default withStyles(styles)(UsuarioForm);