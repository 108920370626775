import gql from 'graphql-tag';

export const GET_EMPRESAS = gql`
{
  clientes {
      id
      nome
      tipopessoa
      nomefantasia
      telefones{
          telefone{
            id
            ddi
            numero
          }
      }
  }
}
`;

export const GET_CLIENTE = gql`
query cliente($id: Int){
    cliente(cliente: { id: $id }){
        id
        tipopessoa
        cpfcnpj
        nome
        nomefantasia
        rgie
        email
        ufemissorrgieid
        validade
        telefones {
            telefone{
                id
                ddi
                numero
                tipotelefone
            }
        }
        endereco {
            id
            cep
            logradouro
            numero
            bairro
            complemento
            municipio {
                id
                nome
                estado {
                    id
                    nome
                    sigla
                }
            }
        }
        usuarios {
            usuario{
                id
                nome
                email
                username
                foto
            }
            perfil{
                id
                nome
            }
        }
    }
}
`;

export const SET_EMPRESA = gql`
mutation adicionarOuAtualizarCliente($cliente: ClienteInput!) {
    adicionarOuAtualizarCliente(cliente: $cliente) {
        id
        nome
    }
  }
`;