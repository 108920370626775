import React from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import green from '@material-ui/core/colors/green';
import CloseIcon from '@material-ui/icons/Close'
import ErrorCircleIcon from '@material-ui/icons/ErrorRounded'
import CheckCircleIcon from '@material-ui/icons/CheckRounded'

import * as graphQLActions from '../../../store/actions'

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const handleClose = () => {
    graphQLActions.CLOSE_SNACKBAR();
}

const CustomSnackbar = () => {
    const { loading, error, data } = useQuery(graphQLActions.GET_SNACKBAR);
    const classes = useStyles();

    if (loading || error)
        return null;

    const { snackbar } = data;

    const snackClass = snackbar.error ? classes.error : classes.success;
    const snackIcon = snackbar.error ? <ErrorCircleIcon className={classes.icon} /> : <CheckCircleIcon className={classes.icon} />;

    const actions = snackbar.noAutoClose ?
        [
            <IconButton
                key="close"
                aria-label="Fechar"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>,
        ]
        : null;

    return <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.noAutoClose ? null : 2500}
        onClose={handleClose}
    >
        <SnackbarContent
            aria-describedby="message-id"
            className={snackClass}
            message={
                <span id="message-id" className={classes.message}>
                    {snackIcon}
                    {snackbar.message}
                </span>
            }
            action={actions}
        />
    </Snackbar>
}

export default CustomSnackbar;