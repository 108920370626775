import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { getIn } from 'formik';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles'

import * as actions from '../../../../store/actions'
import SelectWrapped from '../../../../components/UI/SelectWithAutocomplete/SelectWithAutocomplete';
import Spinner from '../../../../components/UI/Spinner/FullWidthSpinner'
import { ErrorPage } from '../../../../components/ErrorBoundary/ErrorBoundary';
import { fetchFromObject } from '../../../../shared/utilities';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 150
    },
}))

const handleBlur = (formik, campo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(campo, true, true);
};

/**
 * Função para setar o valor no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 */
const handleChange = (event, formik, campo) => {
    formik.setFieldValue(campo, event);
    //TODO: Remover após correção do bug no Formik (https://github.com/jaredpalmer/formik/issues/2266)
    formik.validateForm().then(() => formik.validateForm())
}

const PerfilField = (props) => {
    const classes = useStyles();
    const { formik, disabled } = props;

    const { loading, graphQlError, data } = useQuery(actions.GET_PERFIS);

    if (loading)
        return <Spinner />;

    if (graphQlError)
        return <ErrorPage />

    const nomeCampo = 'perfil';
    const label = 'Perfil';

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    const { perfis } = data;

    const opcoes = perfis.map(({ id, nome }) => (
        {
            value: id,
            label: nome
        }
    ));

    return (
        <FormControl required margin="normal" className={classes.formControl}>
        <SelectWrapped
            disabled={formik.isSubmitting || disabled}
            id={nomeCampo}
            label={label}
            placeholder={label}
            options={opcoes}
            value={fetchFromObject(formik.values, nomeCampo)}
            handleChange={(event) => handleChange(event, formik, nomeCampo)}
            handleBlur={() => handleBlur(formik, nomeCampo)}
            required
            error={Boolean(touched && error)}
        />
        <FormHelperText
            className={classes.mensagemErro}
            error={Boolean(touched && error)}>
            {error && touched && (
                error
            )}
        </FormHelperText>
    </FormControl>
    )
}

export default PerfilField;
