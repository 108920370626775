import React, { Fragment, Component } from 'react';
import { withStyles, Toolbar, Typography, Tooltip, IconButton } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add'

import * as cores from '../../../../../shared/cores';

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.primary.main, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'inline-flex'
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo
    },
    input: {
        padding: '6px 0 0px'
    }
});


class TableToolbar extends Component {

    render() {
        const { title, classes } = this.props;
        const { botaoAdicionarClick } = this.props;

        return (
            <Toolbar
                className={classes.root}>

                <div className={classes.title}>
                    <Typography color="inherit" variant="subtitle1" id="tableTitle">
                        {title}
                    </Typography>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <Fragment>
                        <Tooltip title="Adicionar">
                            <IconButton
                                onClick={botaoAdicionarClick}
                                aria-label="Adicionar"
                                className={classes.actionButton}>
                                <AddIcon/>
                            </IconButton>
                        </Tooltip>
                    </Fragment>
                </div>
            </Toolbar>
        );
    }
}

TableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

export default withStyles(styles)(TableToolbar);