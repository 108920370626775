import React from 'react';
import { TableCell, Typography, Avatar, withStyles } from '@material-ui/core';

import placeholder from '../../../content/images/avatar-placeholder.png'

const styles =  theme => ({
    nomeCell: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginLeft: 0,
        width: '3rem',
        height: '3rem',
    },
});

const usernameTableData = (props) => {
    const { classes, usuario, padding } = props;
    return (
        <TableCell component="th" scope="row" padding={padding} key={usuario.id}>
            <div className={classes.nomeCell}>
                <Avatar alt="Avatar"
                    className={classes.avatar}
                    src={usuario.foto ? usuario.foto : placeholder} />
                <div>
                    <Typography variant="subtitle1">{usuario.nome}</Typography>
                    <Typography variant="caption" >{usuario.email}</Typography>
                </div>
            </div>
        </TableCell>
    )
}

export default withStyles(styles)(usernameTableData);