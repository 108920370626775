import React, { Component } from 'react';
import { DialogActions, Dialog, DialogTitle, Button, withStyles, DialogContent, Paper, Chip, Avatar, Typography, TextField } from '@material-ui/core';
import { FieldArray } from 'formik';

import placeholder from '../../../content/images/avatar-placeholder.png'
import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions';

const styles = (theme) => ({
    dialog: {
        maxWidth: 700,
    },
    chip: {
        margin: theme.spacing(1),
        marginTop: 32,
    },
    usuariosBox: {
        border: '1px solid rgba(0,0,0,0.54)',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 250,
    }
});

// eslint-disable-next-line
const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

class AdicionarUsuariosDialog extends Component {
    state = {
        emailError: null,
        touched: false,
        email: ''
    }

    handleChange = (event) => {
        this.setState({ email: event.target.value });
        this.setState({ touched: true });

        const regEx = RegExp(rEmail);
        if (regEx.test(event.target.value) || event.target.value === '')
            this.setState({ emailError: null });
        else
            this.setState({ emailError: 'E-mail inválido' });

    }

    handleKeyPress = (event, arrayHelpers) => {
        if (event.key === "Enter") {
            if (!this.state.emailError) {
                this.adicionarUsuario(this.state.email, arrayHelpers);
                this.setState({
                    emailError: null,
                    touched: false,
                    email: ''
                });
                return;
            }
        }
    }

    adicionarUsuario = (value, arrayHelpers) => {
        let usuario = {
            id: 0,
            nome: '',
            email: value,
            username: value,
            foto: undefined,
            perfilID: 2,
            ultimoAcesso: ''
        };

        apolloClientProvider.client.query({
            query: actions.GET_USUARIO,
            variables: { email: value },
            fetchPolicy: 'network-only'
        })
            .then(({ data }) => {
                if (data.usuario) {
                    usuario = {
                        id: data.usuario.id,
                        nome: data.usuario.nome,
                        email: data.usuario.email,
                        username: data.usuario.username,
                        foto: data.usuario.foto ? data.usuario.foto : undefined,
                        perfilID: 2,
                        ultimoAcesso: data.usuario.ultimoAcesso
                    }
                }
                arrayHelpers.push(usuario);
            });

    }

    render() {
        const { formik, classes } = this.props;
        const { open, onClose, } = this.props;

        return <Dialog
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="filter-dialog-title"
            scroll="body">
            <DialogTitle
                id="filter-dialog-title"
                className={classes.title}>
                Adicionar usuários
        </DialogTitle>

            <DialogContent>
                <FieldArray
                    name="usuarios"
                    render={
                        arrayHelpers => (
                            <div>
                                <Typography>Usuários</Typography>
                                <Paper className={classes.usuariosBox}>
                                    {formik.values.usuarios.map((usuario, index) => {
                                        return <Chip
                                            key={index}
                                            className={classes.chip}
                                            label={usuario.email}
                                            avatar={<Avatar
                                                src={usuario.foto ? usuario.foto : placeholder} />}
                                        />
                                    })}
                                    <TextField
                                        className={classes.textField}
                                        id="email-usuarios"
                                        label="E-mail"
                                        margin="normal"
                                        value={this.state.email}
                                        onChange={(event) => this.handleChange(event)}
                                        onBlur={(event) => this.handleChange(event)}
                                        error={Boolean(
                                            this.state.touched && this.state.emailError
                                        )}
                                        helperText={this.state.emailError &&
                                            this.state.touched && (
                                                this.state.emailError
                                            )}
                                        FormHelperTextProps={{ error: true }}
                                        onKeyPress={(event) => this.handleKeyPress(event, arrayHelpers)}
                                    />
                                </Paper>
                            </div>
                        )
                    }
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Fechar
        </Button>
            </DialogActions>
        </Dialog>
    }
}

export default withStyles(styles)(AdicionarUsuariosDialog);