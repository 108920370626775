import React, { Fragment, Component } from 'react';
import classNames from 'classnames';
import { withStyles, Toolbar, Typography, Tooltip, IconButton, Input } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'

import * as cores from '../../../shared/cores';

const styles = theme => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.primary.main, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'inline-flex'
    },
    title: {
        flex: '0 0 auto',
        color: cores.titulo
    },
    input: {
        padding: '6px 0 0px'
    }
});


class CommonTableToolbar extends Component {
    state = {
        hideInput: true,
    }

    handleBotaoBuscaClick = () => {
        this.setState(prevState => ({ hideInput: !prevState.hideInput }));
    }

    render() {
        const { title, classes } = this.props;

        const filter = this.props.filterDialog
            ? (<Fragment>
                <Tooltip title="Filtrar lista">
                    <IconButton aria-label="Filtrar lista" className={classes.actionButton}>
                        <FilterListIcon/>
                    </IconButton>
                </Tooltip>
                {this.props.filterDialog}
            </Fragment>)
            : null;

        return (
            <Toolbar
                className={classNames(classes.root)}>

                <div className={classes.title}>

                    <Typography color="inherit" variant="h6" id="tableTitle">
                        {title}
                    </Typography>

                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    {!this.state.hideInput ?
                        <Input
                            placeholder="Digite"
                            className={classes.input}
                            inputProps={{
                                'aria-label': 'Busca',
                            }}
                        /> : null
                    }

                    <Tooltip title="Buscar">
                        <IconButton
                            onClick={this.handleBotaoBuscaClick.bind(this)}
                            //onMouseOver={() => this.setState({ hideInput: false })}
                            // onMouseLeave={() => this.setState({hideInput: true})}
                            aria-label="Buscar"
                            className={classes.actionButton}>
                            <SearchIcon/>
                        </IconButton>
                    </Tooltip>
                    {filter}
                </div>
            </Toolbar>
        );
    }

}

CommonTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    filterDialog: PropTypes.element
};

export default withStyles(styles)(CommonTableToolbar);