import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = theme => ({
    progress: {
        margin: 8 * 2,
    },
    loading: {
        width: '100%',
        textAlign: 'center',
        color: 'white'
    },
});

const fullWidthSpinner = (props) => (
    <div className={props.classes.loading} {...props.containerProps}>
        <CircularProgress
            className={props.classes.progress} />
    </div>
);

export default withStyles(styles)(fullWidthSpinner);