import React from 'react';
import { getIn } from 'formik'
import { FormControl, InputLabel, Select, MenuItem, withStyles, FormHelperText } from '@material-ui/core';
import { fetchFromObject } from '../../../../shared/utilities';

const styles = (theme) => ({
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 200
    },
})

/**
 * Função para setar o valor do Tipo de Pessoa no Formik
 * @method handleChange
 * @param {object} event - Evento
 * @param {object} formik - Props do formik obtidas por RenderProps
 */
const handleChange = (event, formik, nomeCampo) => {
    formik.setFieldValue(nomeCampo, event.target.value);
}

const handleBlur = (formik, nomeCampo) => {
    // this is going to call setFieldTouched and manually update touched.topcis
    formik.setFieldTouched(nomeCampo, true, true);
};

const empresaTipoField = (props) => {
    const { formik, classes } = props;

    const nomeCampo = 'tipoPessoa', label = 'Tipo de Pessoa';

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    return (
        <FormControl required margin="normal" className={classes.formControl}>
            <InputLabel htmlFor={nomeCampo}>{label}</InputLabel>
            <Select
                value={fetchFromObject(formik.values, nomeCampo)}
                onChange={(event) => handleChange(event, formik, nomeCampo)}
                inputProps={{
                    name: nomeCampo,
                    id: nomeCampo
                }}
                onBlur={() => handleBlur(formik, nomeCampo)}
                disabled={formik.isSubmitting}
                error={Boolean(touched && error)}
            >
                <MenuItem value={"FISICA"}>Física</MenuItem>
                <MenuItem value={"JURIDICA"}>Jurídica</MenuItem>
                <MenuItem value={"ORGAO_PUBLICO_FEDERAL"}>Órgão Público Federal</MenuItem>
                <MenuItem value={"ORGAO_PUBLICO_ESTADUAL"}>Órgão Público Estadual</MenuItem>
                <MenuItem value={"OUTROS"}>Outros</MenuItem>
            </Select>
            <FormHelperText
                className={classes.mensagemErro}
                error={Boolean(touched && error)}>
                {error && touched && (
                    error
                )}
            </FormHelperText>
        </FormControl>
    )
};

export default withStyles(styles)(empresaTipoField);


