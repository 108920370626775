import React from 'react';
import { getIn } from 'formik'
import { TextField, withStyles } from '@material-ui/core';
import { fetchFromObject } from '../../../../shared/utilities';

const styles = theme => ({
    textField: {
        margin: theme.spacing(1),
        width: 550,
        display: 'flex',
    }
});

const UsuarioConfirmacaoSenhaField = (props) => {
    const { formik, classes } = props;

    const nomeCampo = "confirmacaoSenha", label = 'Confirme a senha'

    let error = getIn(formik.errors, nomeCampo);
    const touched = getIn(formik.touched, nomeCampo);

    error = error && (error.value ? error.value : error)

    return (
        <React.Fragment>
            <TextField
                className={classes.textField}
                type="password"
                id={nomeCampo}
                label={label}
                autoComplete="confirmacaoSenha"
                value={fetchFromObject(formik.values, nomeCampo)}
                error={Boolean(touched && error)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                margin="normal"
                helperText={error && touched && (
                    error
                )}
                FormHelperTextProps={{error: true}}
            />

        </React.Fragment>
    )
}

export default withStyles(styles)(UsuarioConfirmacaoSenhaField);
