import React from 'react';
import { Typography, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import { Link } from 'react-router-dom';
import { mdfe } from '../../../shared/sistemas'

const useStyles = makeStyles(theme => ({
    loginComponent: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    agradecimento: {
        margin: 'auto',
        textAlign: 'center',
        marginBottom: 50
    },
    button: {
        margin: 'auto',
        marginTop: 50,
    }
}));

const Confirmado = () => {
    const classes = useStyles();

    return <div data-test='component-confirmado' className={classes.loginComponent}>
        <Typography variant="h1" className={classes.agradecimento}>
            Obrigado por confirmar sua conta!
        </Typography>
        <Button
            className={classes.button}
            href = {mdfe.urlBase + "/auth/login"}
            color="primary"
            aria-label="loginLink">
            Clique aqui para entrar no ZMDF-e
        </Button>
    </div>
}

export default Confirmado;