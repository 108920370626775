import axios from 'axios';
import { ReactIsInDevelomentMode } from './shared/utilities';

const developmentMode = ReactIsInDevelomentMode();

const baseURL = developmentMode ? 'https://localhost:44365/api/' : 'https://gestao.zeusautomacao.com.br/api/';

const instance = axios.create({
    baseURL,
    withCredentials: true,
});

instance.defaults.withCredentials = true;

export default instance;