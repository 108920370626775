import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
// import asyncComponent from './hoc/asyncComponent/asyncComponent';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';

import Layout from './hoc/Layout/Layout';
import Empresas from './containers/Empresas/Empresas';
import Usuarios from './containers/Usuarios/Usuarios'
//import Configuracoes from './containers/Configuracoes/Configuracoes'
import UsuariosCreate from './containers/Usuarios/UsuariosCreate';
import EmpresasCreate from './containers/Empresas/EmpresasCreate';
import Login from './components/Auth/Login/Login'
import Confirmacao from './components/Auth/Confirmacao/Confirmacao'
import Confirmado from './components/Auth/Confirmado/Confirmado'
import * as actions from './store/actions'
import AlterarSenha from './components/Auth/AlterarSenha/AlterarSenha';
import CustomSnackbar from './components/UI/CustomSnackbar/CustomSnackbar';
import FullWidthSpinner from './components/UI/Spinner/FullWidthSpinner';
import apolloClientProvider from './apolloClientProvider';
import ResetSenha from './components/Auth/ResetSenha/ResetSenha';
import ResetConfirmado from './components/Auth/ResetConfirmado/ResetConfirmado';

// const asyncEmpresas = asyncComponent(() => {
//   return import('./containers/Empresas/Empresas');
// });

// const asyncUsuarios = asyncComponent(() => {
//   return import('./containers/Usuarios/Usuarios');
// });

// const asyncConfiguracoes = asyncComponent(() => {
//   return import('./containers/Configuracoes/Configuracoes');
// });

const useStyles = makeStyles({
  app: {
    height: '100%',
  },
  layout: {
    height: '100%',
  }
})

function Routes(props) {
  const { loading, error, data } = useQuery(actions.GET_AUTH);
  const classes = useStyles();
  const { currentLocation } = props;

  if (loading)
    return <FullWidthSpinner />;

  if (error)
    return error;

  const { auth } = data;

  if (auth) {
    return (
      <Layout className={classes.layout}>
        <CustomSnackbar />
        <Switch>
          <Route path='/auth/alterarsenha' component={AlterarSenha} />
          <Route path='/empresas/novo/:id?' component={EmpresasCreate} />
          <Route path='/empresas' component={Empresas} />
          <Route path='/usuarios/novo/:id?' component={UsuariosCreate} />
          <Route path='/usuarios' component={Usuarios} />
          {/* <Route path='/configuracoes' component={Configuracoes} /> */}
          <Redirect to='/empresas' />
        </Switch>
      </Layout >
    );
  }
  else {
    return (
      <React.Fragment>
        <CustomSnackbar />
        <Switch>
          <Route path='/auth/login' component={Login} />
          <Route path='/auth/registro' component={Confirmacao} />
          <Route path='/auth/resetsenha' component={ResetSenha} />
          <Route path='/auth/confirmado' component={Confirmado} />
          <Route path='/auth/resetconfirmado' component={ResetConfirmado} />
          <Redirect to={{
            pathname: '/auth/login',
            state: { from: currentLocation }
          }} />
        </Switch>
      </React.Fragment>
    );
  }
}


const defaultLoggedPage = '/empresas'

export function UnroutedApp() {
  const location = useLocation();
  useEffect(() => {
    const currentLocation = location.pathname;
    actions.CHECK_AUTH(currentLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const { loading, error, data } = useQuery(actions.GET_STARTING_PAGE);

  if (loading)
    return <FullWidthSpinner />;

  if (error)
    return error;

  let { startingPage } = data;
  const currentLocation = location.pathname;

  if (startingPage) {
    apolloClientProvider.client.mutate({ mutation: actions.SET_STARTING_PAGE, variables: { input: false } })
    if (startingPage === '/') {
      startingPage = defaultLoggedPage;
    }
    else if (startingPage === '/auth/login') {
      startingPage = defaultLoggedPage;
    }
    if (startingPage !== currentLocation) {
      return <Redirect to={startingPage} />
    }
  }

  return (
    <div data-test='component-app' className={classes.app}>
      <Routes currentLocation={currentLocation} />
    </div>
  );
}

export default UnroutedApp;