import React from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { withStyles, ListItemIcon, ListItemText, ListItem } from '@material-ui/core';

const styles = {
    cinzaMenu: {
        color: '#f2f2f29e'
    },
    guttersPadding16: {
        paddingLeft: 16,
        paddingRight: 16
    },
    guttersPadding32: {
        paddingLeft: 32,
        paddingRight: 16
    },
    active: {
        borderLeft: '2px solid #ccc',
    }
};

const renderLink = React.forwardRef((itemProps, ref) => (
    // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
    <RouterLink {...itemProps} ref={ref} />
));

const navigationItem = (props) => {
    return <ListItem
        button
        component={renderLink}
        selected={props.location.pathname === props.link}
        to={props.link}
        //activeClassName={props.classes.active}
        classes={{
            gutters: props.doublePaddingLeft ? props.classes.guttersPadding32 : props.classes.guttersPadding16,
            selected: props.classes.active
         }}>
        <ListItemIcon className={props.classes.cinzaMenu}>
            {props.icon}
        </ListItemIcon>
        <ListItemText
            classes={{ primary: props.classes.cinzaMenu }}
            primary={props.texto} />
    </ListItem>
};

export default withStyles(styles)(withRouter(navigationItem));
