import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    loginComponent: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    agradecimento: {
        margin: 'auto',
        textAlign: 'center',
        marginBottom: 50
    },
    button: {
        margin: 'auto',
        marginTop: 50,
    }
}));

const Confirmado = (props) => {
    const classes = useStyles();
    return <div data-test='component-confirmado' className={classes.loginComponent}>
        <Typography variant="h1" className={classes.agradecimento}>
            Sua senha foi alterada com sucesso!
        </Typography>
    </div>
}

export default Confirmado;