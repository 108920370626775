import React from "react";
import { Formik, Form } from "formik";
import PropTypes from 'prop-types';
import { IconButton, Fab } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'

import Yup from '../../../yup'
import UsuarioNomeField from "../../../containers/Usuarios/Form/Fields/UsuarioNomeField";
import UsuarioCargoField from "../../../containers/Usuarios/Form/Fields/UsuarioCargoField";
import UsuarioDepartamentoField from "../../../containers/Usuarios/Form/Fields/UsuarioDepartamentoField";
import UsuarioEmailField from "../../../containers/Usuarios/Form/Fields/UsuarioEmailField";
import UsuarioSenhaField from "../../../containers/Usuarios/Form/Fields/UsuarioSenhaField";
import UsuarioFotoField from "../../../containers/Usuarios/Form/Fields/UsuarioFotoField";
import UsuarioConfirmacaoSenha from "../../../containers/Usuarios/Form/Fields/UsuarioConfirmacaoSenha";

const useStyles = makeStyles(theme => ({
    gridContainer: {
        display: 'grid',
        paddingTop: 100,
        gridGap: '30px',
        gridTemplateColumns: '0px auto auto'
    },
    column1: {
        display: 'flex',
        flexDirection: 'row-reverse',
        marginTop: theme.spacing(4),
        gridColumnStart: 2,
        gridColumnEnd: 2,
    },
    column2: {
        gridColumnStart: 3,
        gridColumnEnd: 3,
    },
    buttonConfirm: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: 20
    },
    buttonClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 20,
        width: 56,
        height: 56
    },
}))

const ConfirmacaoSchema = Yup.object().shape({
    nome: Yup.string()
        .required('Requerido'),
    cargo: Yup.string(),
    departamento: Yup.string(),
    email: Yup.string()
        .required('Requerido')
        .email('E-mail inválido'),
    senha: Yup.string()
        .required("Requerido")
        .min(6, "A senha deve ter no mínimo 6 caracteres"),
    confirmacaoSenha: Yup.string()
        .required('Requerido')
        .oneOf([Yup.ref('senha')], 'A senha e confirmação devem ser iguais'),
    foto: Yup.string()
});

export const ConfirmacaoForm = (props) => {
    const classes = useStyles()
    return (
        <Formik
            initialValues={props.initialValues}
            enableReinitialize={true}
            validationSchema={ConfirmacaoSchema}
            onSubmit={(values, formikBag) => {
                const addHandlers = promise =>
                    promise.then(
                        result => {
                            formikBag.resetForm();
                            formikBag.setSubmitting(false);

                            return result;
                        },
                        error => {
                            formikBag.setSubmitting(false);
                            formikBag.setErrors(error.validationErrors);

                            throw error;
                        }
                    );
                return props.onSubmit(values, addHandlers);
            }}>
            {formikProps => {
                const form = (
                    <React.Fragment>
                        <Form>
                            <div className={classes.gridContainer}>
                                <div className={classes.column1}>
                                    <UsuarioFotoField formik={formikProps} />
                                </div>
                                <div className={classes.column2}>
                                    <IconButton
                                        className={classes.buttonClose}
                                        // color="primary"
                                        aria-label="Cancelar"
                                        onClick={props.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                    <UsuarioNomeField formik={formikProps} />
                                    <UsuarioCargoField formik={formikProps} />
                                    <UsuarioDepartamentoField formik={formikProps} />
                                    <UsuarioEmailField formik={formikProps} disabled />
                                    <UsuarioSenhaField formik={formikProps} />
                                    <UsuarioConfirmacaoSenha formik={formikProps} />
                                    <Fab
                                        disabled={formikProps.isSubmitting}
                                        className={classes.buttonConfirm}
                                        type="submit"
                                        color="primary"
                                        aria-label="Adicionar">
                                        <CheckIcon />
                                    </Fab>
                                </div>
                            </div>
                        </Form>
                    </React.Fragment>
                );

                return props.children({
                    form,
                    isDirty: formikProps.dirty,
                    isSubmitting: formikProps.isSubmitting,
                    setFieldValue: formikProps.setFieldValue,
                    setFieldError: formikProps.setFieldError,
                    setFieldTouched: formikProps.setFieldTouched,
                    handleSubmit: formikProps.handleSubmit,
                    handleCancel: formikProps.resetForm,
                });
            }}
        </Formik>
    );
}

ConfirmacaoForm.propTypes = {
    initialValues: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired
}

export default ConfirmacaoForm;